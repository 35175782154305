import { styled } from '@mui/material/styles';
import axios from "axios";
import { createContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "./footer";
import GDPR from "./gdpr";
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 58;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------
export const UserContext = createContext({})
export default function DashboardLayout() {
    const [open, setOpen] = useState(true);
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const fetchUser = () => {
        return axios.get('/auth/user').then(response => {
            setUser(response.data.user);
            // if (user.profile?.salutation === undefined && user?.role === 'testator' && !subscribed && !['/dashboard/app'].includes(location.pathname)) {
            //     toastr.error(`Update profile to proceed`);
            //     // return <Navigate to={`/dashboard/app`} />;
            //     navigate('/dashboard/app');
            // }
        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        fetchUser();
        const beforeUnload = (event) => {
            if (window.location.search !== '?reloading')
                event.returnValue = "";
        };
        const forceLogout = (event) => {
            navigator.sendBeacon("/auth/logout");
        };
        window.addEventListener('beforeunload', beforeUnload)
        window.addEventListener('pagehide', forceLogout);
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
            window.removeEventListener('pagehide', forceLogout);
        }
    }, [])

    const location = useLocation();

    const subscribed = useMemo(() => {
        // let consumed = user?.Subscriptions ?.length === 0;
        let consumed = true;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(sub => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1) {
                    const today = new Date();
                    const subDay = new Date(userSub.createdAt);
                    const subDays = (today - subDay) / 86400 / 1000;
                    const allowed = sub.days;
                    if (subDays > allowed)
                        consumed = true;
                    else
                        consumed = false;

                }
            }
        });
        return !consumed;
    }, [user]);

    const isProfileComplete = useMemo(() => {
        // if(user?.profile === null){
        //     return true;
        // }
        // return false;

        if (user.role === 'testator') {
            if (user.profile === null) {
                return false;
            }
            if (user.profile.salutation) {
                return true;
            }
            return false;
        }
        if (user.role === 'partner') {
            if (user.profile === null) {
                return false;
            }
            if (user.profile.dob) {
                return true;
            }
            return false;
        }
        return false;
    }, [user]);

    const handleFAQClick = () => {
        // Define the URL of your external FAQ page
        const faqURL = 'https://getwilldone.com/faqs'; // Replace with your actual FAQ URL

        // Open the URL in a new tab
        window.open(faqURL, '_blank');
    };
    if (["partner", "testator"].includes(user?.role) && !subscribed && !isProfileComplete && !['/dashboard/app'].includes(location.pathname)) {
        // return <Navigate to={`/dashboard/app`} />
        // toastr.error(`Update profile to proceed`);
        Swal.fire({
            position: "top-center",
            icon: "error",
            color: '#fff',
            background: '#00838F',
            toast: true,
            title: "Update profile to proceed",
            showConfirmButton: false,
            timer: 3000
        });
        // return <Navigate to={`/dashboard/app`} />;
        navigate('/dashboard/app');
    }

    // if (["testator"].includes(user?.role) && !subscribed && !['/dashboard/subscriptions', '/dashboard/app'].includes(location.pathname)) {
    //     Swal.fire({
    //         position: "top-center",
    //         icon: "error",
    //         color: '#fff',
    //         background: '#00838F',
    //         toast: true,
    //         title: "Buy Make your own Will to proceed",
    //         showConfirmButton: false,
    //         timer: 4000
    //     });
    //     return <Navigate to={`/dashboard/subscriptions?ref=${encodeURI(location.pathname)}`} />
    // }
    if (["partner"].includes(user?.role) && user.partner_enroll_status !== "APPROVED" && !subscribed && !['/dashboard/subscriptions', '/dashboard/app'].includes(location.pathname)) {
        Swal.fire({
            position: "top-center",
            icon: "error",
            color: '#fff',
            background: '#00838F',
            toast: true,
            // title: "Apply Demo Coupon Code to continue",
            // text: "OR Enroll as a Partner",
            html: "<p>Apply Demo Coupon Code to continue</p><strong>OR</strong><p>Enroll as a Partner</p>",
            showConfirmButton: false,
            timer: 5000
        });
        return <Navigate to={`/dashboard/subscriptions?ref=${encodeURI(location.pathname)}`} />
    }
    if (["partner"].includes(user?.role) && user.partner_enroll_status === "APPROVED" && !subscribed && !['/dashboard/subscriptions', '/dashboard/app'].includes(location.pathname)) {
        Swal.fire({
            position: "top-center",
            icon: "error",
            color: '#fff',
            background: '#00838F',
            toast: true,
            // title: "Please purchase Make your own Will service",
            // text: "OR Apply Demo Coupon Code to continue",
            html: "<p>Please purchase Make your own Will service</p><strong>OR</strong><p>Apply Demo Coupon Code to continue</p>",
            showConfirmButton: false,
            timer: 5000
        });
        return <Navigate to={`/dashboard/subscriptions?ref=${encodeURI(location.pathname)}`} />
    }
    return (
        <>
            <UserContext.Provider value={{ user, fetchUser }}>
                <StyledRoot>
                    <Header openNav={open} onOpenNav={() => setOpen(!open)} />

                    <Nav openNav={open} onCloseNav={() => setOpen(false)} />

                    <Main sx={{ maxWidth: open ? `calc(100% - 240px)` : `calc(100% - 48px - 24px)`, marginLeft: 'auto' }}>
                        <Outlet />
                        <Footer />
                    </Main>
                </StyledRoot>
            </UserContext.Provider>
        </>
    );
}
