import { Box, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';
import { ContainedButton1, TextedButton1 } from '../Button';

const BottomFloatingAction = ({
    message = '',
    containerStyles = {},
    firstButtonText = '',
    onFirstButtonClick = noop,
    firstButtonDisabled = false,
    firstButtonSx = {},
    secondButtonText = '',
    onSecondButtonClick = noop,
    secondButtonDisabled = false,
    secondButtonSx = {},
    thirdButtonText = '',
    onThirdButtonClick = noop,
    thirdButtonDisabled = false,
    thirdButtonSx = {}
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.primary.dark}90`,
                borderRadius: '15px',
                padding: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40%',
                zIndex: 1000,
                flexFlow: 'row wrap',
                ...containerStyles,
            }}
        >
            <Box sx={{width: '40%', '@media (max-width:1408px)': { width: "100%", textAlign: "center", marginBottom: "8px"}}}>
                <Typography variant="smallText2">{message}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', justifyContent: 'center', '@media (max-width:1194px)': {flexFlow: 'nowrap'}}}>
                <TextedButton1
                    disabled={firstButtonDisabled}
                    onClick={onFirstButtonClick}
                    sx={{ ...firstButtonSx }}
                >
                    <Typography variant="smallText2">{firstButtonText}</Typography>
                </TextedButton1>
                <ContainedButton1
                    disabled={secondButtonDisabled}
                    onClick={onSecondButtonClick}
                    sx={{ ...secondButtonSx }}
                >
                    <Typography variant="smallText2">{secondButtonText}</Typography>
                </ContainedButton1>
                <ContainedButton1
                    sx={{ ...thirdButtonSx }}
                    disabled={thirdButtonDisabled}
                    onClick={onThirdButtonClick}
                >
                    <Typography variant="smallText2">{thirdButtonText}</Typography>
                </ContainedButton1>
            </Box>
        </Box>
    );
};

export default BottomFloatingAction;
